import React, { CSSProperties, ReactElement } from "react";
import Image from "next/image";
import styles from "./styles.module.scss";
import { TFeatureList } from "@page-builder/ts/generated";

export default function FeatureList({
    props = {},
}: {
    props?: TFeatureList["props"];
}): ReactElement {
    const {
        title,
        description,
        items,
        order = ["image", "title", "description"],
        gridColumns = {
            sm: 1,
            md: 2,
            lg: 3,
        },
    } = props;

    return (
        <section className={`${styles["mainSection"]} `}>
            <div>
                {title && (
                    <div
                        className={`mb-6 ${styles.title}`}
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                )}
                {description && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                        className={styles.description}
                    />
                )}
                <div
                    style={
                        {
                            "--grid-cols-sm": gridColumns["sm"] || "1",
                            "--grid-cols-md":
                                gridColumns["md"] || gridColumns["sm"] || "2",
                            "--grid-cols-lg":
                                gridColumns["lg"] ||
                                gridColumns["md"] ||
                                gridColumns["sm"] ||
                                "3",
                            "--grid-cols-xl":
                                gridColumns["xl"] ||
                                gridColumns["lg"] ||
                                gridColumns["md"] ||
                                gridColumns["sm"] ||
                                "3",
                        } as CSSProperties
                    }
                    className={`grid gap-10 lg:gap-20 mt-6 ${styles["grid"]}`}
                >
                    {items?.map((item, index) => {
                        const content = order.map((key) => {
                            if (key === "title") {
                                return (
                                    <div
                                        key={key}
                                        className={`font-medium text-lg lg:text-xl ${styles["item-title"]}`}
                                        dangerouslySetInnerHTML={{
                                            __html: item.title ?? "",
                                        }}
                                    />
                                );
                            } else if (key === "description") {
                                return item.showList ? (
                                    <ul key={key}>
                                        {item.list?.map((listItem) => (
                                            <li
                                                key={listItem}
                                                className={styles["listItem"]}
                                            >
                                                {listItem}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p
                                        key={key}
                                        className="font-light text-sm text-center"
                                    >
                                        {item.description}
                                    </p>
                                );
                            } else if (key === "image" && item?.image) {
                                return (
                                    <div
                                        key={key}
                                        className={`flex ${styles["imageContainer"]}`}
                                    >
                                        <div
                                            className={`relative w-full ${styles["image"]}`}
                                        >
                                            <Image
                                                src={item.image}
                                                layout="fill"
                                                alt={item.title}
                                            />
                                        </div>
                                    </div>
                                );
                            } else {
                                return <React.Fragment key={index} />;
                            }
                        });

                        return (
                            <div
                                key={index}
                                className={`text-center flex flex-col gap-4 ${styles.item}`}
                            >
                                {content.map((item) => {
                                    return item;
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}
